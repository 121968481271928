<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-4" dark color="blue" v-bind="attrs" v-on="on">
        <v-icon dark>
          mdi-plus
        </v-icon>
        Nuevo
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Nuevo cliente
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Nombre"
          v-model="nuevoCliente.nombre"
        ></v-text-field>

        <v-text-field
          label="Primer apellido"
          v-model="nuevoCliente.primerApellido"
        ></v-text-field>

        <v-text-field
          label="Segundo Apellido"
          v-model="nuevoCliente.segundoApellido"
        ></v-text-field>

        <v-text-field label="DNI" v-model="nuevoCliente.dni"></v-text-field>

        <v-menu
          ref="menu"
          v-model="fechaNacimiento"
          :close-on-content-click="false"
          :return-value.sync="nuevoCliente.fechaNacimiento"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="nuevoCliente.fechaNacimiento"
              label="Fecha de nacimiento"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="nuevoCliente.fechaNacimiento"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="fechaNacimiento = false">
              Cancelar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="nuevoCliente.fechaNacimiento = ''"
            >
              Limpiar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(nuevoCliente.fechaNacimiento)"
            >
              Aceptar
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-text-field
          label="Domicilio"
          v-model="nuevoCliente.residencia"
        ></v-text-field>

        <v-combobox
          v-model="nuevoCliente.colegio"
          :items="colegios"
          label="Colegio"
          clearable
        ></v-combobox>

        <v-select
          :items="cursos"
          label="Curso"
          clearable
          v-model="nuevoCliente.curso"
        ></v-select>

        <v-select
          :items="niveles"
          label="Nivel"
          clearable
          v-model="nuevoCliente.nivel"
        ></v-select>

        <v-text-field
          label="Observaciones"
          v-model="nuevoCliente.observaciones"
        ></v-text-field>

        <v-checkbox
          v-model="nuevoCliente.proteccionDatos"
          label="Protección de datos"
        ></v-checkbox>

        <v-checkbox
          v-model="nuevoCliente.usoImagenes"
          label="Uso de imágenes"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue"
          text
          @click="
            toggleDialog = false;
            $emit('edit');
          "
        >
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="crear()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NuevoCliente',
  props: ['colegios'],
  data() {
    return {
      loading: false,
      toggleDialog: false,
      fechaNacimiento: false,
      cursos: ['1º', '2º', '3º', '4º', '5º', '6º', 'Otro'],
      niveles: [
        'Infantil',
        'Primaria',
        'Secundaria',
        'Bachillerato',
        'Opositores',
        'Adultos',
        'Otro',
      ],
      nuevoCliente: {
        nombre: '',
        primerApellido: '',
        segundoApellido: '',
        dni: '',
        fechaNacimiento: '',
        residencia: '',
        colegio: '',
        curso: '',
        nivel: '',
        observaciones: '',
        proteccionDatos: false,
        usoImagenes: false,
      },
    };
  },
  methods: {
    async crear() {
      try {
        this.loading = true;

        const res = await axios.post(process.env.VUE_APP_API_URL + '/clientes/nuevo', this.nuevoCliente);

        this.loading = false;

        this.toggleDialog = false;

        this.$router.push({
          name: 'Cliente',
          params: { id: res.data._id },
        });
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
  },
};
</script>
