<template>
  <div>
    <p v-show="errorTableData" class="text-center">
      <v-icon>mdi-alert-circle</v-icon>
      Error obteniendo los datos
    </p>

    <v-card v-if="!errorTableData">
      <v-card-title>
        <NuevoCliente :colegios="colegios"></NuevoCliente>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :search="search"
        :loadingTableData="loadingTableData"
        loadingTableData-text="Cargando..."
        @click:row="rowClick"
      >
        <template v-slot:item.fechaAlta="{ item }">
          {{ new Date(item.fechaAlta).toLocaleDateString() }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import NuevoCliente from '../components/Clientes/NuevoCliente';

export default {
  data() {
    return {
      tableData: [],
      errorTableData: false,
      loadingTableData: true,
      search: '',
      tableHeaders: [
        {
          text: 'Fecha de alta',
          filterable: false,
          value: 'fechaAlta',
        },
        { text: 'DNI', value: 'dni' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Primer apellido', value: 'primerApellido' },
        { text: 'Segundo apellido', value: 'segundoApellido' },
        { text: 'Curso', value: 'curso' },
        { text: 'Nivel', value: 'nivel' },
        { text: 'Colegio', value: 'colegio' },
        { text: 'Domicilio', value: 'residencia' },
      ],
    };
  },
  methods: {
    async getTableData() {
      try {
        this.loadingTableData = true;
        const res = await axios.get(process.env.VUE_APP_API_URL + '/clientes');
        this.tableData = res.data;
        this.loadingTableData = false;
      } catch (error) {
        this.loadingTableData = false;
        this.errorTableData = true;
      }
    },
    async rowClick(row) {
      this.$router.push({ name: 'Cliente', params: { id: row._id } });
    },
  },
  computed: {
    colegios: function() {
      const colegios = [];

      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].colegio) {
          if (colegios.indexOf(this.tableData[i].colegio) === -1) {
            colegios.push(this.tableData[i].colegio);
          }
        }
      }

      return colegios;
    },
  },
  components: {
    NuevoCliente,
  },
  mounted() {
    this.getTableData();
    this.$emit('usePanel', true);
  },
};
</script>
